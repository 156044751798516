<template>
  <div>
    <v-sheet
      v-show="!loading"
      class="pa-1"
    >
      <result-chart
        :questions="questions"
        @selectedCategory="filterCategory"
      />
      <v-card class="ma-3">
        <v-data-table
          class="custom-dt-header"
          :headers="headers"
          :items="questions"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          multi-sort="multi-sort"
          dense="dense"
          @click:row="showStatementModal"
        >
          <template v-slot:item.noFlg="{item}">
            <v-icon
              :color="item.noFlg ? 'grey' : 'green'"
              @click.prevent="setFlg(item)"
            >
              mdi-bookmark-plus
            </v-icon>
          </template>
          <template v-slot:item.noMemoFlg="{item}">
            <v-icon
              :color="item.noMemoFlg ? 'grey' : 'green'"
              size="22px"
            >
              mdi-file-edit
            </v-icon>
          </template>
          <template v-slot:item.last_answer="{item}">
            <v-icon
              v-if="item.last_answer === true"
              color="green"
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-else-if="item.last_answer === false"
              color="red"
            >
              mdi-close
            </v-icon>
            <v-icon
              v-else
              color="grey"
            >
              mdi-minus
            </v-icon>
          </template>
          <template v-slot:item.correctHistory="{item}">
            <v-row class="no-gutters">
              <correct-history-icon :correct="item.last_correct" />
              <correct-history-icon :correct="item.second_to_last_correct" />
              <correct-history-icon :correct="item.third_to_last_correct" />
            </v-row>
          </template>
          <template v-slot:item.headline="{item}">
            <span style="white-space: pre-wrap;">{{ item.headline }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-sheet>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate="indeterminate"
        size="64"
      />
    </v-overlay>
    <v-snackbar
      v-model="apiFailed"
      bottom="bottom"
      color="error"
    >
      データの取得に失敗しました、再度お試しください
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="apiFailed = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="bookMarked"
      bottom="bottom"
      color="green"
    >
      ブックマークしました。
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="bookMarked = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="unBookMarked"
      bottom="bottom"
      color="grey"
    >
      ブックマークを解除しました。
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="unBookMarked = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <question-statement
      :open.sync="openQuestionStatement"
      :question="selectedQuestion"
      @memoFlg="setMemoFlg"
    />
  </div>
</template>

<script>
import axios from "axios";
import ResultChart from "@/components/Result/ResultChart";
import CorrectHistoryIcon from "@/components/Result/CorrectHistoryIcon.vue";
import QuestionStatement from "@/components/Result/QuestionStatement.vue";
import dayjs from "dayjs";

export default {
  name: "PastResult",
  components: { QuestionStatement, ResultChart, CorrectHistoryIcon },
  data() {
    return {
      tab: null,
      loading: false,
      apiFailed: false,
      bookMarked: false,
      unBookMarked: false,
      questions: [],
      search: '',
      openQuestionStatement: false,
      questionId: undefined,
      selectedQuestion: undefined,
      disableShowStatement: false,
      sortBy: ['updated_at'],
      sortDesc: [true],
    }
  },
  computed: {
    headers() {
      return [
        { text: '回答日', value: "updated_at" },
        { text: 'ブックマーク', value: "noFlg" },
        { text: 'メモ', value: "noMemoFlg" },
        { text: '正解', value: "last_answer" },
        { text: '科目', value: "level0" },
        { text: '分類', value: "level1" },
        { text: '出典', value: "exam" },
        { text: '問題番号', value: "question_num" },
        { text: '直近3回の正解', value: "correctHistory" },
      ]
    }
  },
  created() {
    this.fetchResults()
  },
  methods: {
    async fetchResults() {
      this.loading = true;
      await axios.get('api/v1/users/results').then((res) => {
        this.questions = res.data['single_questions'];
        this.questions = this.questions.map((question) => {
          const headline = [question.level2, question.level3, question.level4].filter(l => !!l).join("\n");
          const correctHistory = [question.last_correct, question.second_to_last_correct, question.third_to_last_correct].filter(l => !!l).length;
          const noMemoFlg = question.memo === null || question.memo === ''
          const updated_at = dayjs(question.updated_at).format('YYYY/MM/DD')
          return { ...question, updated_at, headline, correctHistory, noMemoFlg, noFlg: !question.flg }
        })
      }).catch(() => {
        this.apiFailed = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    async setFlg(question) {
      this.disableShowStatement = true
      question.noFlg = !question.noFlg
      const body = { questions: [{ id: question.id, flg: !question.noFlg, }] }
      const { status } = await axios.post('api/v1/users/questions', body)
      if (status === 200) {
        question.noFlg ? this.unBookMarked = true : this.bookMarked = true;
      } else {
        question.noFlg = !question.noFlg
      }
      this.disableShowStatement = false
    },
    setMemoFlg(questionId, flg) {
      const question = this.questions.find(q => q.id === questionId)
      question.noMemoFlg = !flg
    },
    filterCategory(value) {
      this.search = value
    },
    showStatementModal(row) {
      if (this.disableShowStatement) return;

      this.questionId = row.id
      this.selectedQuestion = row
      this.openQuestionStatement = true
    },
  }
}
</script>

<style scoped>
.custom-dt-header .v-data-table-header-mobile .v-data-table-header__icon {
  opacity: 1 !important;
}
</style>
